//.........global dev.........
// const baseUrl_admin = 'https://dev.app.marx.lk/admin_global';
// const baseUrl_public = 'https://dev.app.marx.lk/global';
// const returnMarxUrl = 'https://dev.global.marxpay.com';
const baseUrl_admin = 'https://dev.app.marx.lk/global-admin';
const baseUrl_public = 'https://dev.app.marx.lk/global-api';
const returnMarxUrl = 'https://dev.global.marxpay.com';



export {baseUrl_admin,baseUrl_public,returnMarxUrl};


// note : effect of merchant type
